import React from "react";
import Breadcrumb from "../components/breadcrumb";
import BookForm from "../components/book-form";
import { model } from "../json";
// import Card from "../components/card";
// import Card2 from "../components/card2";
import TwoVehCard from "../components/twoVehCard";
import Slider from "../components/Slider";

const Jeep2 = () => {
  const final = model[1].map((item) => item.name);
  const slides = [
    {
      image:
        "https://www.jeep-india.com/content/dam/cross-regional/apac/jeep/en_in/homepage-banner/Wrangler-Banner-HP-Mob-V1.jpg.img.2000.jpg",
    },
    // {
    //   image: require("../assets/others/jeep_banner.jpg"),
    // },
    {
      image:
        "https://www.jeep-india.com/content/dam/cross-regional/apac/jeep/en_in/meridian/MERIDIAN-X-SATD-Mob1.jpg.img.2000.jpg",
    },
    {
      image:
        "https://www.jeep-india.com/content/dam/cross-regional/apac/jeep/en_in/homepage-banner/Compass-Mob-06June24.jpg.img.2000.jpg",
    },
    {
      image:
        "https://www.jeep-india.com/content/dam/cross-regional/apac/jeep/en_in/homepage-banner/Banner-GC-Mob-02May24.jpg.img.2000.jpg",
    },
  ];
  return (
    <div>
      {/* <img
        src={require("../assets/others/jeep_banner.jpg")}
        className="object-cover max-w-full lg:-mt-20"
        alt=""
      /> */}
      <Slider slides={slides} />
      <Breadcrumb pageName="Jeep" />

      <div className="grid gap-10 px-1 mx-auto my-10 md:grid-cols-3 max-w-7xl">
        <div className="col-span-2">
          <p className="text-2xl font-semibold">Jeep</p>
          <p className="mt-5 text-black/80">
            Jeep, an iconic name synonymous with adventure, stands as a beacon
            of exploration. With a rich heritage deeply rooted in American
            history, Jeep is a trailblazer in off-road capabilities and timeless
            design. Creators of legendary vehicles like the Jeep Wrangler, Jeep
            embodies freedom and exploration. From conquering terrains to
            navigating cities, Jeep vehicles offer versatility and reliability.
            Committed to innovation, Jeep redefines adventure, providing an
            unparalleled experience on and off the road. Reflecting resilience
            and determination, Jeep invites you to explore the unknown. Join us
            on a journey of discovery with Jeep – where every journey is
            extraordinary.
          </p>

          <p className="mt-10 mb-2 text-2xl font-semibold">Orange Auto Group</p>
          <p className="text-black/80">
            Welcome to Orange Motors, your authorized dealer for Jeep Cars India
            Ltd. We are proud to serve customers in Visakhapatnam, offering a
            wide range of Jeep vehicles including the Jeep Compass, Jeep
            Meridian, and Wrangler. As a leading Jeep dealer, we are dedicated
            to providing top-notch service and an exceptional car-buying
            experience. Our commitment to quality and customer satisfaction
            makes us the best choice for all your Jeep needs. Visit Orange
            Motors today and explore the latest Jeep cars and SUVs.
          </p>
        </div>
        <div className="col-span-2 p-2 lg:col-span-1">
          <BookForm final={final} />
        </div>
      </div>
      <div className="grid gap-5 py-10 mx-auto lg:grid-cols-3 max-w-7xl md:grid-cols-2 ">
        {model[1].map((item) => (
          <TwoVehCard
            key={item.id}
            id={item?.id}
            position="1"
            banner={item?.pic}
            title={item?.name}
            price={item?.price}
            fuel={item?.fuel}
            transmission={item?.transmission}
            engine={item?.cc}
            category={item?.variants[0]}
            brochure={item?.brochure}
            weight={item?.weight}
            variants={item?.variants}
          />
        ))}
      </div>
    </div>
  );
};

export default Jeep2;
