import { PiGasPumpLight } from "react-icons/pi";
import TransmissionSvg from "../assets/svg/transmission.svg";
import CurrencyFormatter from "./currency-formatter";
import { Link } from "react-router-dom";

const Card = ({
  id,
  title,
  engine,
  fuel,
  position,
  transmission,
  price,
  banner,
}) => {
  return (
    <div className="overflow-hidden border rounded-lg group border-gray-200/70 ">
      <div className="overflow-hidden bg-slate-100/80">
        <Link to={`/vehicles/${position}/${id}`}>
          <img
            src={banner}
            className="object-cover w-full duration-150 group-hover:scale-110 "
            loading="lazy"
            alt=""
          />{" "}
        </Link>
      </div>
      <div className="mx-7 md:mx-4 lg:mx-7 xl:mx-4">
        {/* title */}
        <div className="py-3 space-y-1">
          <h5 className="text-xl font-medium text-black/80">{title}</h5>
        </div>
        <div className="w-full h-px bg-gray-200/80"></div>

        {/* tiles */}
        <div className="flex items-start justify-between py-3 text-sm capitalize text-gray-800/80">
          <div className="flex flex-col items-center w-1/3 text-center">
            {/* <PiEngineLight className="w-6 h-6" /> */}
            <img
              src={require("../assets/others/engine.png")}
              alt="Engine"
              className="h-6"
            />
            <p className="uppercase">{engine} cc</p>
          </div>
          <div className="flex flex-col items-center justify-center w-1/3 text-center">
            <PiGasPumpLight className="w-6 h-6" />
            <p>{fuel}</p>
          </div>
          <div className="flex flex-col items-center justify-center w-1/3 text-center">
            <img src={TransmissionSvg} className="w-4 h-5 mb-1" alt="" />
            <p>{transmission}</p>
          </div>
        </div>

        <div className="w-full h-px bg-gray-200/80"></div>
        <div className="flex items-center justify-between py-3">
          {/* price */}
          {price === 0 ? (
            <h4 className="font-semibold">
              <Link href="/contact-us">Request Price</Link>
            </h4>
          ) : (
            <h4 className="font-semibold">{CurrencyFormatter.format(price)}</h4>
          )}
          <div>
            {position && (
              <Link
                to={`/vehicles/${position}/${id}`}
                className="text-sm text-blue-500/90"
              >
                View Details
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
