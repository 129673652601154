import React from "react";

const TataCurvvADAS = () => {
  return (
    <div className="container px-4 py-6 mx-auto my-10 lg:max-w-7xl lg:my-14">
      <h1 className="mb-6 text-3xl font-bold">
        Tata Curvv ADAS: A Step Toward Safer and Smarter Driving
      </h1>

      <p className="mb-4 text-gray-700">
        The Tata Curvv has generated excitement in India’s EV market with its
        sleek coupe-inspired SUV design, futuristic technology, and electric
        powertrain. Now, Tata Motors is raising the bar even higher by
        integrating Advanced Driver Assistance Systems (ADAS) into the Curvv,
        making it not only stylish and efficient but also one of the safest
        vehicles on Indian roads. ADAS represents a breakthrough in vehicle
        safety, giving drivers advanced tools to prevent accidents, reduce
        fatigue, and improve their overall driving experience.
      </p>

      <h2 className="mt-6 mb-4 text-xl font-semibold">
        What is ADAS and Why is it Important?
      </h2>
      <p className="mb-4 text-gray-700">
        ADAS, or Advanced Driver Assistance Systems, is a collection of
        technologies designed to make driving safer and more comfortable. These
        systems use sensors, cameras, and artificial intelligence to assist
        drivers in navigating complex road situations. From collision warnings
        to adaptive cruise control, ADAS can actively prevent accidents and make
        everyday driving less stressful. As road safety becomes a top priority
        in the automobile industry, ADAS plays a key role in reducing human
        error, which is a leading cause of accidents globally.
      </p>

      <h2 className="mt-6 mb-4 text-xl font-semibold">
        Tata’s Commitment to Safety
      </h2>
      <p className="mb-4 text-gray-700">
        Tata Motors has consistently prioritized safety, as seen in the 5-star
        Global NCAP ratings achieved by popular models like the Tata Nexon. By
        incorporating ADAS into the Tata Curvv, Tata is reinforcing its
        commitment to creating vehicles that protect passengers while also
        giving drivers peace of mind. Tata’s approach to safety aligns with
        global trends, offering Indian consumers advanced technologies that have
        become standard in premium vehicles worldwide.
      </p>

      <h2 className="mt-6 mb-4 text-xl font-semibold">
        Overview of Tata Curvv and its Unique Features
      </h2>
      <p className="mb-4 text-gray-700">
        The Tata Curvv is a unique blend of coupe-inspired styling and SUV
        functionality, designed to appeal to urban drivers who want
        sophistication and adventure in one package. As part of Tata’s EV
        lineup, the Curvv brings modern aesthetics and innovation to the Indian
        electric vehicle market. Positioned between the Nexon and larger SUVs,
        the Curvv combines compact maneuverability with a spacious, tech-savvy
        interior. With ADAS features integrated, the Curvv sets a new benchmark
        in Tata’s range of electric and sustainable vehicles.
      </p>

      <h2 className="mt-6 mb-4 text-xl font-semibold">
        Key ADAS Features in Tata Curvv
      </h2>
      <p className="mb-4 text-gray-700">
        The Tata Curvv’s ADAS package includes several cutting-edge features
        aimed at enhancing driver awareness and reducing accidents. Here’s a
        look at the key ADAS technologies expected in the Curvv:
      </p>

      <div className="space-y-4">
        <div className="p-4 bg-gray-100 rounded-lg">
          <h3 className="text-lg font-semibold">
            1. Forward Collision Warning (FCW) and Automatic Emergency Braking
            (AEB)
          </h3>
          <p className="text-gray-700">
            These features are designed to alert drivers to potential front-end
            collisions. FCW uses sensors to detect obstacles ahead and warns the
            driver if a collision risk is identified. AEB takes things further
            by automatically applying brakes if the driver does not react in
            time, helping to reduce the severity of impact or avoid collisions
            entirely.
          </p>
        </div>

        <div className="p-4 bg-gray-100 rounded-lg">
          <h3 className="text-lg font-semibold">
            2. Adaptive Cruise Control (ACC)
          </h3>
          <p className="text-gray-700">
            ACC maintains a safe following distance from vehicles ahead, making
            highway driving more comfortable and safer. This system
            automatically adjusts the car’s speed based on traffic flow,
            reducing the need for manual braking and acceleration on long
            journeys.
          </p>
        </div>

        <div className="p-4 bg-gray-100 rounded-lg">
          <h3 className="text-lg font-semibold">
            3. Lane Departure Warning (LDW) and Lane Keep Assist (LKA)
          </h3>
          <p className="text-gray-700">
            LDW alerts drivers when the car unintentionally drifts out of its
            lane, especially useful on highways. LKA gently guides the vehicle
            back into its lane, offering a level of automation that supports
            safer driving by preventing lane drifting and reducing the chances
            of side collisions.
          </p>
        </div>

        <div className="p-4 bg-gray-100 rounded-lg">
          <h3 className="text-lg font-semibold">
            4. Blind Spot Detection (BSD) and Rear Cross Traffic Alert (RCTA)
          </h3>
          <p className="text-gray-700">
            BSD is designed to detect vehicles in the driver’s blind spots,
            giving alerts to help prevent unsafe lane changes. RCTA warns the
            driver of approaching vehicles when reversing, which is especially
            helpful in crowded parking areas.
          </p>
        </div>

        <div className="p-4 bg-gray-100 rounded-lg">
          <h3 className="text-lg font-semibold">
            5. Traffic Sign Recognition (TSR)
          </h3>
          <p className="text-gray-700">
            TSR is an intelligent system that scans and displays road signs,
            helping drivers stay aware of speed limits, road rules, and other
            vital information. This feature helps drivers stay within legal
            limits and ensures better compliance with road safety regulations.
          </p>
        </div>

        <div className="p-4 bg-gray-100 rounded-lg">
          <h3 className="text-lg font-semibold">
            6. Driver Monitoring System (DMS)
          </h3>
          <p className="text-gray-700">
            DMS uses camera-based technology to monitor the driver’s behavior,
            detecting drowsiness or signs of distraction. If DMS senses that the
            driver’s attention is compromised, it sends an alert, encouraging
            the driver to stay focused or take a break if necessary.
          </p>
        </div>

        <div className="p-4 bg-gray-100 rounded-lg">
          <h3 className="text-lg font-semibold">
            7. Enhanced Parking Assistance
          </h3>
          <p className="text-gray-700">
            The Curvv’s parking assist features use sensors and cameras to make
            parking in tight spaces easier and safer. The system provides
            guidance to help drivers park with precision, reducing the risk of
            minor accidents in crowded urban settings.
          </p>
        </div>
      </div>

      <h2 className="mt-6 mb-4 text-xl font-semibold">
        Impact of ADAS on Driving Experience
      </h2>
      <p className="mb-4 text-gray-700">
        The ADAS features in Tata Curvv work in tandem to provide a more
        controlled, stress-free driving experience. For new drivers, these
        systems offer a sense of security and confidence on the road, while
        experienced drivers benefit from extra support during long drives and in
        challenging traffic conditions. ADAS technologies reduce the mental load
        of driving, allowing drivers to focus on the road without constantly
        worrying about obstacles or errors. The combination of advanced safety
        systems with the Curvv’s high-performance EV technology results in a
        vehicle that is as enjoyable to drive as it is safe.
      </p>

      <h2 className="mt-6 mb-4 text-xl font-semibold">
        ADAS in the Indian Context
      </h2>
      <p className="mb-4 text-gray-700">
        Implementing ADAS in India comes with its own set of challenges,
        including unpredictable traffic, diverse road conditions, and varying
        infrastructure quality. Tata has tailored the ADAS in Curvv to address
        these unique challenges, ensuring the systems work effectively on Indian
        roads. By offering features that enhance driver awareness and respond
        well to India’s dynamic driving environment, Tata Motors is setting a
        precedent for advanced safety in domestic vehicles.
      </p>

      <h2 className="mt-6 mb-4 text-xl font-semibold">Conclusion</h2>
      <p className="mb-4 text-gray-700">
        The introduction of ADAS in the Tata Curvv underscores Tata Motors’
        commitment to combining safety, innovation, and design. With advanced
        driver assistance systems like Forward Collision Warning, Adaptive
        Cruise Control, Lane Keep Assist, and more, the Tata Curvv elevates
        safety standards for Indian electric vehicles. As Tata Motors continues
        to push the boundaries of automotive technology, the Curvv ADAS model
        offers Indian drivers a safer, smarter, and more enjoyable driving
        experience, paving the way for the future of mobility in India.
      </p>
    </div>
  );
};

export default TataCurvvADAS;
