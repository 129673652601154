import React from "react";
import Breadcrumb from "../components/breadcrumb";
import BookForm from "../components/book-form";
import { model } from "../json";
import TwoVehCard from "../components/twoVehCard";
import Slider from "../components/Slider";
import { Helmet } from "react-helmet";

const HarleyDavidson = () => {
  const final = model[9].map((item) => item.name);
  const slides = [
    // {
    //   image: require("../assets/harley-davidson/harley_home_banner.webp"),
    // },
    {
      image:
        "https://www.harley-davidson.com/content/dam/h-d/images/content-images/short-hero/2024/bikes-lp-shd.jpg",
    },
    {
      image:
        "https://www.harley-davidson.com/content/dam/h-d/images/promo-images/2024/short-hero/adv-hp-shd.jpg",
    },
    {
      image:
        "https://www.harley-davidson.com/content/dam/h-d/images/promo-images/2024/short-hero/gat-hp-shd.jpg",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>
          Best Harley Davidson Showroom Vijayawada | Harley Davidson Dealers
          Vijayawada
        </title>
        <meta
          name="description"
          content="Harley Davidson Authorized Byke Dealers in Vijayawada. Visit Showroom today to avail offers on Harley Davidson Bykes. Book A Test Drive."
        />
      </Helmet>
      <Slider slides={slides} />
      <Breadcrumb pageName="Harley Davidson" />

      <div className="grid gap-10 px-1 mx-auto my-10 md:grid-cols-3 max-w-7xl">
        <div className="col-span-2">
          <p className="text-2xl font-semibold">Harley Davidson</p>
          <p className="mt-5 text-black/80">
            Harley Davidson, an iconic name synonymous with freedom and the open
            road, stands as a symbol of adventure and rebellion. With a rich
            heritage deeply rooted in American culture, Harley Davidson is a
            pioneer in motorcycle innovation and timeless design. Creators of
            legendary bikes like the Harley Davidson Fat Boy and Harley Davidson
            Sportster, the brand embodies the spirit of individualism and
            exploration. From cruising down endless highways to navigating
            bustling city streets, Harley Davidson motorcycles offer unmatched
            power and style. Committed to pushing boundaries, Harley Davidson
            redefines the thrill of riding, providing riders with an
            exhilarating experience like no other. Reflecting resilience and
            determination, Harley Davidson invites you to embrace the freedom of
            the open road. Join us on a journey of discovery with Harley
            Davidson – where every ride is an adventure.
          </p>

          <p className="mt-10 mb-2 text-2xl font-semibold">Orange Auto Group</p>
          <p className="text-black/80">
            Welcome to Orange Motors, your authorized dealer for Harley Davidson
            India Ltd. We are excited to serve the vibrant city of Vijayawada,
            offering a range of iconic Harley Davidson bikes. Whether you're
            looking for a retro bike, a powerful sports bike, or a stylish youth
            bike, we have something for every enthusiast. Our commitment to
            quality and exceptional service makes us the top choice for Harley
            Davidson bikes. Visit Orange Motors today and experience the thrill
            of riding a Harley.
          </p>
        </div>
        <div className="col-span-2 p-2 lg:col-span-1">
          <BookForm final={final} />
        </div>
      </div>

      <div className="grid gap-5 py-10 mx-auto lg:grid-cols-3 md:grid-cols-2 max-w-7xl xl:grid-cols-4">
        {model[9].map((item) => (
          <TwoVehCard
            key={item.id}
            id={item?.id}
            position="9"
            banner={item?.pic}
            title={item?.name}
            price={item?.price}
            fuel={item?.fuel}
            transmission={item?.transmission}
            engine={item?.cc}
            category={item?.variants[0]}
            brochure={item?.brochure}
            weight={item?.weight}
            variants={item?.variants}
          />
        ))}
      </div>
    </div>
  );
};

export default HarleyDavidson;
