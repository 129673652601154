import React from "react";
import { Link } from "react-router-dom";

const Blogs = () => {
  const blogsData = [
    {
      title:
        "Tata Nexon CNG: The Eco-Friendly Alternative to Tata’s Bestselling Compact SUV",
      description:
        "The Tata Nexon, one of India’s most popular compact SUVs, has earned a reputation for its robust design, feature-rich experience, and impressive safety standards. As Tata Motors continues to explore eco-friendly alternatives, the anticipated launch of the Tata Nexon CNG adds a new chapter to the vehicle’s story, giving Indian buyers an affordable, greener option. The CNG variant offers a practical, cost-effective alternative, catering to the demand for lower fuel costs and reduced emissions, while retaining the Nexon’s trusted performance and style.",
      image: "",
      date: "2024-10-15",
      link: "/blogs/tata-nexon-cng",
    },
    {
      title: "Tata Curvv EV: The Game-Changer from Tata Motors ",
      description:
        "As the Indian automobile industry rapidly moves toward electrification, Tata Motors has been leading the charge, introducing innovative electric vehicles (EVs) that appeal to both urban drivers and those seeking eco-friendly travel. Among Tata’s latest EV offerings, the Tata Curvv EV stands out with its blend of futuristic design, impressive performance, and now, an eye-catching orange color that is grabbing attention across the market. This bold choice not only highlights the car’s design but also makes a statement for drivers looking for an EV that truly stands out.",
      image: "",
      date: "2024-09-30",
      link: "/blogs/tata-curvv-ev",
    },
    {
      title: "Nissan Magnite: The Stylish and Affordable Compact SUV",
      description:
        "The Nissan Magnite has quickly become one of the most popular compact SUVs in the Indian market, known for its eye-catching design, impressive features, and affordability. Designed specifically to cater to urban drivers and small families, the Magnite combines Nissan’s global standards with features tailored for the Indian audience. With a competitive price range, strong performance, and advanced technology, the Magnite is a compelling option for those seeking an affordable and stylish SUV.",
      image: "",
      date: "2024-09-25",
      link: "/blogs/nissan-magnite",
    },
    {
      title: "Tata Curvv ADAS: A Step Toward Safer and Smarter Driving",
      description:
        "The Tata Curvv has generated excitement in India’s EV market with its sleek coupe-inspired SUV design, futuristic technology, and electric powertrain. Now, Tata Motors is raising the bar even higher by integrating Advanced Driver Assistance Systems (ADAS) into the Curvv, making it not only stylish and efficient but also one of the safest vehicles on Indian roads. ADAS represents a breakthrough in vehicle safety, giving drivers advanced tools to prevent accidents, reduce fatigue, and improve their overall driving experience.",
      image: "",
      date: "2024-09-20",
      link: "/blogs/tata-curvv-adas",
    },
    {
      title: "Jeep Compass: The Ultimate Guide to the Compact SUV",
      description:
        "The Jeep Compass has captured the hearts of SUV enthusiasts worldwide, offering the perfect blend of rugged design, advanced technology, and impressive off-road capabilities. Positioned as a versatile, mid-size SUV, the Compass brings together Jeep’s legacy of adventure and modern comforts, making it a popular choice among urban and adventure-focused drivers alike. With a sleek design, strong performance options, and a range of trims, the Jeep Compass is a compelling choice in the competitive compact SUV segment.",
      image: "",
      date: "2024-09-18",
      link: "/blogs/jeep-compass",
    },
    {
      title:
        "Tata Assure: Tata Motors’ Comprehensive Solution for Certified Pre-Owned Cars",
      description:
        "In India, the demand for pre-owned vehicles has been on the rise, fueled by factors like affordability, value for money, and a growing number of first-time buyers. Tata Motors has responded to this demand with its Tata Assure program, a certified pre-owned vehicle initiative that offers high-quality, thoroughly inspected used cars with the assurance of reliability, transparency, and support. With Tata Assure, customers can enjoy the benefits of a new car buying experience in a pre-owned model, complete with warranties, service packages, and easy financing options.",
      image: "",
      date: "2024-09-10",
      link: "/blogs/tata-assure",
    },
  ];

  return (
    <div className="container min-h-screen px-4 py-6 mx-auto my-10 lg:my-14 lg:max-w-7xl">
      <h1 className="mb-6 text-3xl font-bold">Latest Blogs</h1>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {blogsData.map((blog, index) => (
          <article key={index} className="p-6 bg-white rounded-lg shadow-lg">
            <h4 className="mb-4 text-xl font-semibold">{blog.title}</h4>
            <p
              title={blog.description}
              className="mb-4 text-gray-700 line-clamp-[8]"
            >
              {blog.description}
            </p>
            {/* <p title={blog.date} className="mb-4 text-sm text-gray-500">{blog.date}</p> */}
            <Link
              to={blog.link}
              className="text-secondary hover:underline"
              // target="_blank"
              // rel="noopener noreferrer"
            >
              Read more
            </Link>
          </article>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
