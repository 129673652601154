export const jcbData = [
  {
    name: "Electric Scissors Lifts",
    img: require("../assets/jcb/scissor.jpeg"),
    description:
      "The Electric Scissor Lift is a robust machine that enables you to reach vertical heights with ease at a working height.",
    price: 0,
    brochure: require("../assets/jcb/scissr.pdf"),
  },
  {
    name: "Articulated Booms Lifts",
    img: require("../assets/jcb/boom.jpeg"),
    description:
      "A user-friendly control panel and joystick layout, coupled with function speed control, provide smooth, stable operation.",
    price: 0,
    brochure: require("../assets/jcb/boom.pdf"),
  },
  {
    name: "Silent Diesel Generators",
    img: require("../assets/jcb/generator.jpeg"),
    description:
      "Genset perfectly meets your requirements with high performance, serviceability, strength, safety, space and aesthetics.",
    price: 0,
    brochure: require("../assets/jcb/generator.pdf"),
  },
  {
    name: "T65D Telescopic Boom",
    img: require("../assets/jcb/t65.jpeg"),
    description:
      "Reach higher standards with the T65D JCB Boom: our very first diesel telescopic boom lift. A new contender in 65ft platforms, this telescopic boom is powered by the award-winning JCB 444 EcoMax 74hp engine and boasts a 1000lbs basket capacity to lift even more. It’s a next-gen model, designed to give you the power, efficiency, and durability you need from a telescopic boom lift.",
    price: 0,
    brochure: require("../assets/jcb/t65.pdf"),
  },
];
