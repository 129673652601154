import { Helmet } from "react-helmet";
import Breadcrumb from "../components/breadcrumb";
import { useModelContext } from "../components/ModelProvider";
import { jcbData } from "../json/jcb";
const JCB = () => {
  const { setOpenEnq, setModel } = useModelContext();

  return (
    <>
      <Helmet>
        <title>Tata Car Dealers Hyderabad | Tata Showroom Near Me</title>
        <meta
          name="description"
          content="Orange Tata Authorized Best Tata Car Dealers in Hyderabad. Find your Nearest Orange Tata Car Showroom Near Me. Contact Now For New Tata Cars & Offers."
        />
      </Helmet>
      <img
        src={require("../assets/jcb/7.jpg")}
        className="max-w-full w-full object-cover h-[600px]"
        alt=""
      />
      <Breadcrumb pageName="JCB" />

      <div className="max-w-7xl mx-auto my-10 p-5">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5">
          {jcbData.map((item, index) => (
            <div className="col-span-1 rounded overflow-hidden " key={index}>
              <div className="">
                <img src={item.img} alt={item.name} className="rounded" />
                <div className="space-y-4 my-3">
                  <h1 className="text-xl md:text-3xl font-semibold">
                    {item.name}
                  </h1>
                  <p className="text-sm  line-clamp-2">{item.description}</p>

                  <div className="flex items center space-x-2">
                    <button
                      onClick={() => {
                        setOpenEnq(true);
                        setModel(item.name);
                      }}
                      className="bg-black text-yellow-500 w-full text-center p-2 rounded"
                    >
                      Request Price
                    </button>
                    <a
                      href={item.brochure}
                      rel="noreferrer"
                      target="_blank"
                      className=" bg-yellow-500 w-full text-center p-2 rounded"
                    >
                      Download Brochure
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default JCB;
