import React from 'react'
import { useModelContext } from '../components/ModelProvider';
import { GiAutoRepair, GiCarKey } from 'react-icons/gi';
import { PiSteeringWheelBold } from 'react-icons/pi';

const ModalSideNavigation = () => {
  const { setOpenEnq, setModel } = useModelContext();
  return (
    <div className="fixed z-20 space-y-2 -translate-y-1/2 right-1 bottom-1/3 lg:right-0 ">
      <div
        onClick={() => {
          setOpenEnq(true);
          setModel("Service ");
        }}
        className="flex items-center flex-grow gap-2 px-4 py-2 text-sm text-center text-white duration-300 delay-100 translate-x-32 bg-blue-500 border border-blue-400 rounded-l-full shadow-lg cursor-pointer hover:translate-x-0 group"
      >
        <GiAutoRepair className="text-xl lg:text-2xl" />
        <p className="opacity-0 group-hover:opacity-100"> Book A Service</p>
      </div>
      <div
        onClick={() => {
          setOpenEnq(true);
          setModel("Test Drive ");
        }}
        className="flex items-center flex-grow gap-2 px-4 py-2 text-sm text-center text-white duration-300 delay-100 translate-x-32 bg-blue-500 border border-blue-400 rounded-l-full shadow-lg cursor-pointer hover:translate-x-0 group"
      >
        <PiSteeringWheelBold className="text-xl lg:text-2xl" />
        <p className="opacity-0 group-hover:opacity-100"> Book A Test Drive</p>
      </div>
      <div
        onClick={() => {
          setOpenEnq(true);
          setModel("Vehicle ");
        }}
        className="flex items-center w-full gap-2 px-4 py-2 text-sm text-center text-white duration-300 delay-100 translate-x-32 border rounded-l-full shadow cursor-pointer focus:outline-none bg-secondary hover:translate-x-0 group"
      >
        <GiCarKey className="text-xl lg:text-2xl" />
        <p className="opacity-0 group-hover:opacity-100"> Book A Vehicle</p>
      </div>
    </div>
  );
};

export default ModalSideNavigation;